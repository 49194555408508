import React, { forwardRef, useCallback } from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const TermListIndexes = forwardRef(({ indexes, onRequireMoving }, ref) => {
  const onAnchorClick = useCallback(
    event => {
      event.preventDefault();
      onRequireMoving && onRequireMoving(event.target.dataset['index']);
    },
    [onRequireMoving]
  );

  return (
    <div ref={ref}>
      {indexes.map((index, i) => (
        <InlineBlockWrapper
          key={`term_list_indexes_${i}`}
          className={styles['anchorWrapper']}
        >
          {index !== 'SKIP' ? (
            <a
              href={`#${index}`}
              onClick={onAnchorClick}
              className={styles['anchor']}
              data-index={index}
            >
              {index}
            </a>
          ) : (
            <span className={styles['dot']}>・</span>
          )}
        </InlineBlockWrapper>
      ))}
    </div>
  );
});

export default TermListIndexes;
