import React, { useMemo, useRef, useCallback, useEffect } from 'react';

import useElementSize from '../../hooks/useElementSize';

import TermListSectionHeader from '../../molecules/term/TermListSectionHeader';
import TermListCell from '../../molecules/term/TermListCell';
import TermListIndexes from '../../molecules/term/TermListIndexes';

import * as styles from './index.module.css';

const alphabets = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
const kanas = Array.from('あかさたなはまやらわ');

const TermList = ({ terms }) => {
  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const indexesWrapperRef = useRef();
  const indexesRef = useRef();

  const { size: windowSize } = useElementSize({
    element: isBrowser ? document.body : undefined,
  });

  const { size: indexesSize } = useElementSize({
    element: isBrowser ? indexesRef.current : undefined,
  });

  const alphabetIndexes = useMemo(() => {
    let indexes = [];

    for (let i = 0; i < alphabets.length; i = i + 1) {
      if (terms.find(term => term.key === alphabets[i])) {
        indexes = [...indexes, alphabets[i]];
      } else {
        if (indexes[indexes.length - 1] !== 'SKIP') {
          indexes = [...indexes, 'SKIP'];
        }
      }
    }

    return indexes;
  }, [terms]);

  const kanaIndexes = useMemo(() => {
    let indexes = [];

    for (let i = 0; i < kanas.length; i = i + 1) {
      if (i === kanas.length - 1 && terms.find(term => term.key >= kanas[i])) {
        indexes = [...indexes, kanas[i]];
      } else if (
        terms.find(term => term.key >= kanas[i] && term.key < kanas[i + 1])
      ) {
        indexes = [...indexes, kanas[i]];
      } else {
        if (indexes[indexes.length - 1] !== 'SKIP') {
          indexes = [...indexes, 'SKIP'];
        }
      }
    }

    return indexes;
  }, [terms]);

  const indexes = useMemo(() => {
    return [...alphabetIndexes, ...kanaIndexes];
  }, [alphabetIndexes, kanaIndexes]);

  const sections = useMemo(() => {
    const sections = terms.reduce((acc, current) => {
      if (acc.find(section => section.key === current.key)) {
        return acc.map(section => {
          if (section.key !== current.key) {
            return section;
          } else {
            return {
              ...section,
              terms: [...section.terms, current],
            };
          }
        });
      } else {
        return [
          ...acc,
          {
            key: current.key,
            terms: [current],
          },
        ];
      }
    }, []);

    return sections;
  }, [terms]);

  const onTermListIndexesRequireMoving = useCallback(index => {
    const targetElement = document.getElementById(
      `term_list_scroll_target_${index}`
    );

    if (!targetElement) {
      return;
    }

    const clientElementRect = targetElement.getBoundingClientRect();
    const dy = window.pageYOffset + clientElementRect.top;

    window.scrollTo({ top: dy - 132, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!indexesWrapperRef.current) {
      return;
    }

    if (windowSize.height === 0 || indexesSize.height === 0) {
      return;
    }

    const indexesRefTop = (windowSize.height - indexesSize.height) / 2;

    indexesWrapperRef.current.style.top = `${indexesRefTop}px`;
  }, [windowSize, indexesSize]);

  return (
    <div className={styles['root']}>
      <div className={styles['listArea']}>
        <ul className={styles['list']}>
          {(() => {
            let kis = [...kanaIndexes];

            return sections.map(section => (
              <li
                className={styles['listItem']}
                key={`term_list_section_${section.key}`}
              >
                {(() => {
                  if (alphabets.includes(section.key)) {
                    return (
                      <div
                        style={{ height: '1px', marginBottom: '-1px' }}
                        id={`term_list_scroll_target_${section.key}`}
                      />
                    );
                  }

                  if (kis[0] === 'SKIP') {
                    kis.shift();
                  }

                  if (section.key >= kis[0]) {
                    const index = kis[0];
                    kis.shift();
                    return (
                      <div
                        style={{ height: '1px', marginBottom: '-1px' }}
                        id={`term_list_scroll_target_${index}`}
                      />
                    );
                  }

                  return null;
                })()}

                <TermListSectionHeader label={section.key} />

                <ul className={styles['sectionList']}>
                  {section.terms.map(term => (
                    <li className={styles['sectionListItem']} key={term.id}>
                      <TermListCell term={term} key={term.id} />
                    </li>
                  ))}
                </ul>
              </li>
            ));
          })()}
        </ul>
      </div>

      <div className={styles['indexesArea']}>
        <div className={styles['indexesWrapper']} ref={indexesWrapperRef}>
          <TermListIndexes
            ref={indexesRef}
            indexes={indexes}
            onRequireMoving={onTermListIndexesRequireMoving}
          />
        </div>
      </div>
    </div>
  );
};

export default TermList;
