import React from 'react';

import * as styles from './index.module.css';

const TermListSectionHeader = ({ label }) => {
  return (
    <div className={styles['root']}>
      <span className={styles['label']}>{label}</span>
    </div>
  );
};

export default TermListSectionHeader;
