import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const TermListItem = ({ term }) => {
  const [linkStyle, setLinkStyle] = useState({});

  const onLinkMouseEnter = useCallback(() => {
    setLinkStyle({
      backgroundColor: '#E5E5E5',
    });
  }, []);

  const onLinkMouseLeave = useCallback(() => {
    setLinkStyle({});
  }, []);

  return (
    <div>
      <Link
        className={styles['link']}
        to={`/term/${term.slug}`}
        style={linkStyle}
        onMouseEnter={onLinkMouseEnter}
        onMouseLeave={onLinkMouseLeave}
      >
        <span className={styles['label']}>{term.word}</span>
      </Link>
    </div>
  );
};

export default TermListItem;
